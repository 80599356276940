// =========
/// Colors
// =========
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #a0a0a0;
$color-grey-light: #d4d4d4;

$color-primary: #00A7CE;
$color-secondary: #700606;
$color-error: #dd0b2f;
$color-warning: #ffa500;

/**
** Breakpoints
**/

