.wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;

  @media screen and (max-width: 420px){
    padding: 0 20px;
  }
}