header {
  display: flex;
  justify-content: center;
}

.logo {
  padding-bottom: 20%;
  padding-top: 20%;
}

@media screen and (max-width: 768px) {

  .logo {
    width: 300px;
    padding-bottom: 20%;

  }
}

@media screen and (max-width: 555px) {

  .logo {
    width: 250px;
  }
}

@media screen and (max-width: 420px) {

  .logo {
    width: 200px;

  }

}