body {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #222222;
}


a {
  font-family: Pavanam, sans-serif;
  text-decoration: none;
  color: #000000;
  padding: 13px 15px;
  font-size: 18px;
  height: 0;
  transition: all .3s;

  &:hover.nav-hover {
    background-color: $color-primary;
    color: white;
    height: 100%;
  }
}


hr {
  margin: 40px 0;
}

article {
  padding: 55px 40px 120px 40px;

  @media screen and (max-width: 420px) {
    padding: 55px 20px 120px 20px;

  }
}

h1 {
  margin-bottom: 37px;
  font-size: 24px;
  font-family: Pavanam, sans-serif;
  font-weight: lighter;
  line-height: 35px;

  @media screen and (max-width: 420px) {
   margin-bottom: 25px;
  }
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.4em;
}

.center {
  text-align: center;
}

h3 {
  display: block;
  font-weight: lighter;
  font-size: 15px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: dimgrey;
  font-family: Pavanam, sans-serif;
}

h2 {
  display: block;
  font-size: 32px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: Pavanam, sans-serif;
}

strong {
  font-weight: 700;
  line-height: 1.4em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.list-with-dots {
  list-style-type: circle;
  padding-left: 5%;
}

.list-with-dots-item {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.4em;
}


