.hero {
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 768px){

    padding: 0;

  }

  img {
    width: 100%;
    box-shadow: 0 8px 30px 0 rgba(0,0,0,0.5);
    outline: 5px solid rgba(255,255,255,0.78);

    @media screen and (max-width: 768px){

    box-shadow: none !important;
      outline: none !important;


    }
  }

}

.navi-img {
  display: none;

  @media screen and (max-width: 420px) {
    display: flex;
  }
}

.navi-img-big {

  display: flex;

  @media screen and (max-width: 420px) {
    display: none;
  }
}

