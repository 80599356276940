.nav {

  display: flex;
  justify-content: center;

  ul {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  li {
    display: inline;
  }
}

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.menu-btn__burger {
  width: 45px;
  height: 6px;
  background: #000000;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 45px;
  height: 6px;
  background: #000000;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.menu-btn {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {

  .background-nav-filters {

    z-index: 10;

    opacity: 0;
    transition: opacity .4s;
  }

  .effects {
    backdrop-filter: blur(8px);
    background-color: rgba(0,0,0,0.5);

    position: fixed;
    width: 100vw;
    height: 100vh;

    opacity: 1;
  }



  .nav-list {
    position: fixed;
    left: -101%;
    transition: left .3s ease-in-out;

    background-color: white;
    width: 70%;
    display: grid;
    z-index: 99;
    top: 0;
    padding-bottom: 100vh ;
    padding-top: 80px;

  }

  .nav-list.show {
    left: 0;
  }


  .menu-btn {
    position: fixed;
    z-index: 105;
    top: 0;

  }

  .nav {
    position: absolute;
    z-index: 100;

    ul {
      margin: 0;

      li {
        padding: 20px 0;
      }
    }

  }
}

.is-active {
color: white;
  background-color: #00A7CE;

}


.is-active-mobile {

  @media screen and (max-width: 768px) {
    background-color: $color-primary;
    padding: 20px;
  }

}







